import React, { Component } from "react";
import "./Portfolio.css";
export default class portfolio extends Component {
  render() {
    return (
      <div>
        <div className="portfolio__wrapper">
          <div className="portfolio_items p1"></div>
          <div className="portfolio_items p2"></div>
          <div className="portfolio_items p3"></div>
        </div>
        <div className="portfolio_wrapper_button">
          <button
            className="Portfolio_button"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://portfolio.techsnapie.com/";
            }}
          >
            Explore <span>More</span>
          </button>
        </div>
      </div>
    );
  }
}
