import React, { Component } from "react";
import { Link } from "react-scroll";

import "./navbar.css";

export class Navbar extends Component {
  render() {
    return (
      <div className="Navbar">
        <div className="Navbar__left">
          <span className="Navbar__brand">TechSnapie </span>Solutions
        </div>

        <div className="Navbar__right">
          <ul>
            <li>
              <Link to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li>
              <Link to="about" smooth={true} duration={500}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="services" smooth={true} duration={500}>
                Services
              </Link>
            </li>
            <li>
              <Link to="portfolio" smooth={true} duration={500}>
                Portfolio
              </Link>
            </li>
            <li>
              <Link to="contact" smooth={true} duration={500}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Navbar;
