import React, { Component } from "react";
import "./Home.css";
import { Element } from "react-scroll";
import img1 from "../video/img1.jpg";
import img2 from "../video/img2.jpg";

export default class Home extends Component {
  render() {
    return (
      <Element id="home">
        <div className="img__container">
          <img src={img1} alt="" srcset="" />
          <img className="fadeInClass" src={img2} alt="" srcset="" />

          <div className="img__content">
            <p>The Only Way To</p>
            <p className=".Animation__text">
              ' <span>Perfection</span> '
            </p>
          </div>
        </div>
      </Element>
    );
  }
}
